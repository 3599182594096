<template>
  <v-dialog v-model="isCancelModalOpen" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("cancel_transfer_title") }}</v-card-title
      >

      <v-card-text>
        <span class="text-h5">{{ $t("cancel_transfer_text") }} </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="close()" color="indigo" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          @click="confirm()"
          :loading="isLoading"
          :disabled="isLoading"
          text
        >
          {{ $t("cancel_transfer") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
export default {
  mixins: [validationRules],
  props: {
    isCancelModalOpen: Boolean,
    toggleCancelModal: Function,
    transfer: Object,
  },
  data() {
    return {
      valid: false,
      isLoading: false,
      reason_cancellation: "",
      reasonCancellationRules: [
        (v) => !!v || "The cancellation reason is required",
        (v) =>
          (v && v.length <= 25) ||
          "Please write something less than 25 characters",
      ],
    };
  },
  mounted: function () {
    this.currentTransfer = { ...this.transfer };
  },
  methods: {
    close() {
      this.toggleCancelModal();
    },
    async confirm() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("transferProducts/cancel", {
          transfer_id: this.currentTransfer.id,
        });
        this.close();
        this.$store.dispatch(
          "alerts/success",
          this.$t("canceled_successfully")
        );
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
